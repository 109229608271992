import { useContext } from 'react';
import { Form, Row, Col, Input } from 'antd';
import { RatioContext, realPx } from '../../static/tools/common';
import TBtn from '../../components/button/touch';
import { fontWeight } from '@mui/system';

const { TextArea } = Input

function Index() {

    const form = Form.useForm();
    const ratio = useContext(RatioContext) || 1;

    const height = realPx(ratio, 64);

    return (
        <Form layout="vertical" className='c-form'>
            <Row gutter={48}>
                <Col span={12} className='wow animate__slideInRight'>
                    <Form.Item label="First name">
                        <Input style={{ height }} maxLength={20} />
                    </Form.Item>
                </Col>
                <Col span={12}  className='wow animate__slideInRight'>
                    <Form.Item label="Last name">
                        <Input style={{ height }} maxLength={20} />
                    </Form.Item>
                </Col>
                <Col span={12}  className='wow animate__slideInRight'>
                    <Form.Item label="Email">
                        <Input style={{ height }} maxLength={30} />
                    </Form.Item>
                </Col>
                <Col span={12}  className='wow animate__slideInRight'>
                    <Form.Item label="Company name">
                        <Input style={{ height }} maxLength={20} />
                    </Form.Item>
                </Col>
                <Col span={24}  className='wow animate__slideInRight'>
                    <Form.Item label="Message">
                        <TextArea placeholder='Type your message...' autoSize={{ minRows: 6, maxRows: 6 }} maxLength={300} />
                    </Form.Item>
                </Col>
                <Col span={24} className="animate__animated btn-pulse">
                    <TBtn name="Get in Touch" style={{ width: realPx(ratio, 480), margin: '0 auto', marginTop: realPx(ratio, 40), fontWeight: 'bold' }} />
                </Col>
            </Row>
        </Form>
    )
}

export default Index;