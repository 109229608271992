import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './menu.css';
import Seconds from './seconds';
import retargeting from '../../static/images/retargeting@2x.png';
import useracquisition from '../../static/images/useracquisition@2x.png';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';


function Index({ ratio, realPx }: any) {
  const navigate = useNavigate()
  const location = useLocation();
  const style = {
    li: {
      margin: '0 ' + realPx(ratio, 20),
      fontSize: realPx(ratio, 18),
      lineHeight: realPx(ratio, 100)
    },
  };
  const menus = [{
    name: 'Home'
  }, {
    name: 'Solutions',
    children: [
      {
        name: 'User acquisiton',
        icon: useracquisition
      },
      {
        name: 'Retargeting',
        icon: retargeting
      },
      {
        name: 'CTV',
        icon: useracquisition
      }
    ]
  }, {
    name: 'Research'
  }, {
    name: 'About'
  }];

  const [selected, setSelected] = useState('Home');
  const [secondsSelected, setSecondsSelected] = useState('');

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props}
      disableFocusListener
      disableTouchListener
      classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'transparent',
      maxWidth: 700
    },
    [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
    {
      marginTop: '0px',
    },
  }));

  const handleSelected = (name: string) => {
    if (name !== 'Solutions') {
      // setSelected(name);
      navigate(name === 'Home' ? '/' : '/' + name);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const pathname = location.pathname;
    switch(pathname) {
      case '/Research':
        setSelected('Research');
        setSecondsSelected('');
      break;
      case '/About':
        setSelected('About');
        setSecondsSelected('');
      break;
      case '/UserAcquisiton':
        setSelected('Solutions');
        setSecondsSelected('User acquisiton');
      break;
      case '/Retargeting':
        setSelected('Solutions');
        setSecondsSelected('Retargeting');
      break;
      case '/CTV':
        setSelected('Solutions');
        setSecondsSelected('CTV');
      break;
      default:
        setSelected('Home');
        setSecondsSelected('');
      break;
    }
    console.log('location', pathname);
  }, [])

  const handleSecondsSelected = (name: string) => {
    // console.log('CTV', name)
    // setSelected('Solutions');
    // setSecondsSelected(name);
    navigate(name === 'User acquisiton' ? '/UserAcquisiton' : '/'+name);
  };

  return (
    <ul className="Meun-container">
      {
        menus.map((item: any, index: number) => (
          <HtmlTooltip
            key={index}
            title={
              item.children ?
                <Seconds data={item.children} secondsSelected={secondsSelected} handleSecondsSelected={handleSecondsSelected} ratio={ratio} realPx={realPx} />
                : null}
          >
            <li className={selected === item.name ? 'selected ' + item.name : item.name} style={style.li} onClick={() => handleSelected(item.name)}>
              {item.name}
            </li>
          </HtmlTooltip>
        ))
      }
    </ul>
  );
}

export default Index;
