
import React, { useContext } from 'react';
import { RatioContext, realPx } from '../../static/tools/common';
import homeBannerhz from '../../static/images/opt_home_bannerhz.png';
import './alert.css';

function Index() {
    const ratio = useContext(RatioContext) || 1;
    const style = {

        topBtn: {
            padding: `${realPx(ratio, 14)} ${realPx(ratio, 15)}`,
            width: realPx(ratio, 258),
            fontSize: realPx(ratio, 16),
            borderRadius: realPx(ratio, 50),
        },
        topBtnIcon: {
            marginRight: realPx(ratio, 10),
            width: realPx(ratio, 22),
        },
    }

    return (

        <div className='Banner-Alert' style={style.topBtn}>
            <img src={homeBannerhz} style={style.topBtnIcon} alt="icon" />
            <span>Top #1 Marketing Agency</span>
        </div>
    )
}

export default Index;