import React, { useImperativeHandle, forwardRef } from 'react';
import './menu.css';

const Index = forwardRef(({ data, ratio, realPx, secondsSelected, handleSecondsSelected }: any, ref) => {
    useImperativeHandle(ref, () => ({
        
    }));

    const style = {
        container: {
            width: realPx(ratio, 8)
        },
        item: {
            marginRight: realPx(ratio, 8),
            padding: realPx(ratio, 26),
            fontSize: realPx(ratio, 16),
            borderRadius: realPx(ratio, 12)
        },
        icon: {
            marginRight: realPx(ratio, 10),
            width: realPx(ratio, 40),
            height: realPx(ratio, 40),
        },
        img: {
            width: realPx(ratio, 27),
            marginTop: realPx(ratio, 6),
        }
    };

    return (
        <div className='SMeun-container'>
            {
                (data || []).map((item: any, index: number) => (
                    <div key={index} className={item.name === secondsSelected ? 'SMeun-container-active' : ''} style={style.item} onClick={() => handleSecondsSelected(item.name)}>
                        <span className="SMeun-item-icon" style={style.icon}>
                            <img style={style.img} src={item.icon} alt="icon" />
                        </span>
                        <span>{item.name}</span>
                    </div>
                ))
            }
        </div>
    );
})

export default Index;
