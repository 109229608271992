import React, { createContext, useEffect, useState } from 'react';
import './App.css';
import rt from './static/router';
import {
  RouterProvider,
} from "react-router-dom";
import { RatioContext } from './static/tools/common';


function App() {

  // const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [ratio, setRatio] = useState(1);

  useEffect(() => {
    const handleResize = () => {
      // setScreenWidth(window.innerWidth);
      setRatio((window.innerWidth / 1920).toFixed(2) as any);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <RatioContext.Provider value={ratio as any}>
      <div className="App">
        <RouterProvider router={rt} />
      </div>
    </RatioContext.Provider>
  );
}

export default App;
