import {createContext} from 'react';
import { WOW } from 'wowjs';

// 全局比例
export const RatioContext = createContext(null);
// 像素换算
export const realPx = (radio: number, px: number) => (radio || 1) * px + 'px';

export const wowinit = () =>  {

        
    new WOW({
        boxClasses: 'wow',
        animateClass: 'animated',
        offset: 20,
        mobile: true,
        live: false
    }).init();
}