import React, { useContext } from 'react';
import './banner.css';
import { RatioContext, realPx } from '../../../static/tools/common';
import lightLeft from '../../../static/images/opt_lightleft.png';
import lightRight from '../../../static/images/opt_lightright.png';
import bannerpeople from '../../../static/images/opt_research_banner.png';
import homeBtnarow from '../../../static/images/opt_home_btnarow.png';
import 'animate.css';


function Index() {
    const ratio = useContext(RatioContext) || 1;
    const style = {
        bHeight: {
            height: realPx(ratio, 666),
        },
        lightLeft: {
            top: 0,
            left: 0,
            width: realPx(ratio, 400),
        },
        lightRight: {
            bottom: realPx(ratio, -100),
            right: 0,
            width: realPx(ratio, 400),
        },
        topBtn: {
            padding: `${realPx(ratio, 14)} ${realPx(ratio, 15)}`,
            width: realPx(ratio, 258),
            fontSize: realPx(ratio, 16),
            borderRadius: realPx(ratio, 50),
        },
        bannerImgStuff: {
            top: realPx(ratio, 60),
            left: realPx(ratio, 312),
        },
        bannerpeople: {
            width: realPx(ratio, 546),
            zIndex: 2
        },
        info: {
            top: realPx(ratio, 158),
            right: realPx(ratio, 285),
        },
        topBtnIcon: {
            marginRight: realPx(ratio, 10),
            width: realPx(ratio, 22),
        },
        word1: {
            fontSize: realPx(ratio, 48),
            textAlign: 'left'
        },
        word2: {
            fontSize: realPx(ratio, 48),
            lineHeight: realPx(ratio, 56),
            textAlign: 'left'
        },
        word3: {
            fontSize: realPx(ratio, 16),
            marginTop: realPx(ratio, 22),
            marginLeft: 0,
            lineHeight: realPx(ratio, 28),
            width: realPx(ratio, 729)
        },
        opt: {
            marginTop: realPx(ratio, 32),
        },
        btn: {
            width: realPx(ratio, 203),
            height: realPx(ratio, 64),
            borderRadius: realPx(ratio, 100),
            fontSize: realPx(ratio, 20),
        },
        btnImgSpan: {
            width: realPx(ratio, 44),
            height: realPx(ratio, 44),
            marginLeft: realPx(ratio, 18),
        },
        btnImg: {
            width: realPx(ratio, 32),
            marginTop: realPx(ratio, 6),
        }
    };
    return (
        <div className="Banner-container" style={style.bHeight}>
            <img className='bgLight' src={lightLeft} style={style.lightLeft} alt="lightLeft" />
            <img className='bgLight' src={lightRight} style={style.lightRight} alt="lightRight" />
            <div className='wow animate__rotateIn Banner-info'  style={style.bannerImgStuff}>
                <img className='bgLight' src={bannerpeople} style={style.bannerpeople} alt="bannerpeople" />
            </div>
            <div className='Banner-info' style={style.info}>
                <div className='Banner-word1 wow animate__fadeInLeft' style={style.word1 as any}>Harness The Full Potential</div>
                <div className='Banner-word2  wow animate__fadeInLeft' style={style.word2 as any}>Of Our Platform</div>
                <div className='Banner-word3  wow animate__fadeInLeft' style={style.word3}>In recent years, we've witnessed a significant shift in advertising budgets towards connected TV (CTV), reflecting the growing prominence of this channel in reaching and engaging audiences. Brands across industries are increasingly recognizing the potential of CTV advertising to deliver targeted, impactful messages to viewers on the big screen.</div>
                <div className='Banner-opt wow Banner-opt-in' style={style.opt}>
                    <div style={{ ...style.btn, marginRight: realPx(ratio, 18) }}>
                        <span>Get Started</span>
                        <span className='Banner-opt-icon' style={style.btnImgSpan}>
                            <img src={homeBtnarow} style={style.btnImg} alt="icon" />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;
