

import {
  createBrowserRouter,
  createHashRouter
} from "react-router-dom";
import Home from '../pages/home';
import User from '../pages/user';
import Retargeting from '../pages/retargeting';
import CTV from '../pages/ctv';
import Research from '../pages/research';
import About from '../pages/about';
import Test from '../test';

const router = createHashRouter([
  {
    path: "/",
    element: (
      <Home />
    ),
  },
  {
    path: "/UserAcquisiton",
    element: <User/>,
  },
  {
    path: "/Retargeting",
    element: <Retargeting/>,
  },
  {
    path: "/CTV",
    element: <CTV/>,
  },
  {
    path: "/Research",
    element: <Research/>,
  },
  {
    path: "/About",
    element: <About/>,
  },
  {
    path: "/Test",
    element: <Test/>,
  },
]);

export default router;