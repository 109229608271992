import React, { useContext, useEffect } from 'react';
import './home.css';
import Nav from '../../components/nav';
import Banner from './banner';
import ScrollAd from '../../components/scrollAd';
import { RatioContext, realPx, wowinit } from '../../static/tools/common';
import PBtn from '../../components/button/pramiry';
import Alert from '../../components/alert';
import ColorCard from '../../components/card/colorCard';
import Footer from '../../components/footer';
import TBtn from '../../components/button/touch';
import CardSwiper from '../../components/swiper/card';
import proimg1 from '../../static/images/opt_home_proimg1.png';
import proimg2 from '../../static/images/opt_home_proimg2.png';
import homeBtnarow from '../../static/images/opt_home_btnarow.png';
import proline from '../../static/images/opt_home_proline.png';
import proplante from '../../static/images/opt_home_proplante.png';
import proyuan from '../../static/images/opt_home_proyuan.png';
import opt_home_diffimg1 from '../../static/images/opt_home_diffimg1.png';
import proaipower from '../../static/images/opt_home_proaipower.png';
import proretar from '../../static/images/opt_home_proretar.png';
import prouser from '../../static/images/opt_home_prouser.png';
import proline1 from '../../static/images/opt_home_proline1.png';
import proline2 from '../../static/images/opt_home_proline2.png';


function Index() {
    const ratio = useContext(RatioContext) || 1;
    const style = {
        home: {
            paddingTop: realPx(ratio, 100)
        },
        btn: {
            width: realPx(ratio, 203),
            height: realPx(ratio, 64),
            borderRadius: realPx(ratio, 100),
            fontSize: realPx(ratio, 20),
        },
        btnImgSpan: {
            width: realPx(ratio, 44),
            height: realPx(ratio, 44),
            marginLeft: realPx(ratio, 18),
        },
        btnImg: {
            width: realPx(ratio, 32),
            marginTop: realPx(ratio, 6),
        },
        hp: {
            width: realPx(ratio, 1200),
            height: realPx(ratio, 978),
        },
        HPItem1: {
            top: realPx(ratio, 151),
            left: 0,
        },
        HPItem2: {
            top: realPx(ratio, 312),
            left: realPx(ratio, 456),
        },
        HPItem3: {
            top: realPx(ratio, 404),
            right: 0,
        },
        pImg: {
            width: realPx(ratio, 400),
            height: realPx(ratio, 600),
            borderRadius: realPx(ratio, 500),
        },
        pImg2: {
            width: realPx(ratio, 325),
            height: realPx(ratio, 487),
            borderRadius: realPx(ratio, 500),
        },
        HPItemH1: {
            fontSize: realPx(ratio, 48),
        },
        HPItemH3: {
            marginTop: realPx(ratio, 24),
            width: realPx(ratio, 398),
            fontSize: realPx(ratio, 16),
            color: 'rgba(22,27,39,0.5)',
            lineHeight: realPx(ratio, 28)
        },
        ho: {
            width: realPx(ratio, 1200),
            height: realPx(ratio, 1039),
            paddingTop: realPx(ratio, 145),
        },
        hoh1: {
            marginTop: realPx(ratio, 30),
            fontSize: realPx(ratio, 48),
            lineHeight: realPx(ratio, 59),
            foontWeight: 'bold'
        },
        hoh2: {
            marginTop: realPx(ratio, 16),
            width: realPx(ratio, 573),
            fontSize: realPx(ratio, 16),
            lineHeight: realPx(ratio, 28)
        },
        hoImg: {
            width: realPx(ratio, 537),
            height: realPx(ratio, 375),
        },
        growing: {
            paddingTop: realPx(ratio, 101),
            height: realPx(ratio, 800),
        },
        growingh1: {
            fontSize: realPx(ratio, 40),
            lineHeight: realPx(ratio, 47),
            fontWeight: 'bold'
        },
        growingh2: {
            marginTop: realPx(ratio, 16),
            fontSize: realPx(ratio, 16),
            lineHeight: realPx(ratio, 28)
        },
        touch: {
            paddingTop: realPx(ratio, 87),
            height: realPx(ratio, 400),
        },
        touchh1: {
            fontSize: realPx(ratio, 32),
            lineHeight: realPx(ratio, 38),
            foontWeight: 'bold'
        },
        touchh2: {
            marginTop: realPx(ratio, 24),
            fontSize: realPx(ratio, 16),
            lineHeight: realPx(ratio, 19)
        },
        proline: {
            width: realPx(ratio, 114),
            top: realPx(ratio, 554),
            left: realPx(ratio, 17)
        },
        proyuan: {
            bottom: realPx(ratio, 453),
            width: realPx(ratio, 170),
        },
        proplante: {
            bottom: realPx(ratio, 530),
            right: 0,
            width: realPx(ratio, 156),
        },
        proline1: {
            width: realPx(ratio, 47),
            bottom: realPx(ratio, 416),
            left: '-' + realPx(ratio, 54),
        },
        proline2: {
            width: realPx(ratio, 47),
            right: '-' + realPx(ratio, 54),
            bottom: realPx(ratio, 60),
        }
    }
    const opt360Cards = [
        {
            bg: 'rgba(224, 241, 255, 1)',
            icon: prouser,
            title: 'User Acquisition',
            text: 'Opt 360 specializes in acquiring new users for your business through targeted strategies across various channels. We leverage advanced targeting techniques to reach potential customers effectively and drive meaningful results.',
        },
        {
            bg: 'rgba(232, 223, 255, 1)',
            icon: proretar,
            title: 'Retargeting',
            text: "Maximize the value of every interaction with Opt 360's retargeting solutions. We reconnect with users who have shown interest in your brand, encouraging them to return and complete their journey with personalized ads and messages.",
        },
        {
            bg: 'rgba(224, 241, 255, 1)',
            icon: proaipower,
            title: 'AI-Powered Technology',
            text: "Harness the power of AI to precisely reach your target audience and optimize your ad creatives for maximum impact. Our AI tools enable precise audience targeting, creative optimization, bidding strategies, and comprehensive measurement and analysis.",
        }
    ]

    useEffect(() => {
        wowinit();
    }, [])

    return (
        <div className="Home" style={style.home}>
            <div className='Home-nav'>
                <Nav />
            </div>
            <Banner />
            <ScrollAd />
            <div className='Home-Proactive-warp'>
                <div className='Home-Proactive' style={style.hp}>
                    <div style={style.HPItem1} className='home-block-1'>
                        <img className='wow bounceIn' style={style.pImg} src={proimg1} alt="icon" />
                        <div className='wow flipInY bg-stuff-pos'>
                            <img className='routeAni2' style={style.proline} src={proline} alt="proline" />
                        </div>
                    </div>
                    <div style={style.HPItem2} className='Home-Proactive-info'>
                        <div className='wow flipInY bg-stuff-pos' style={style.proyuan}>
                            <img className='routeAni-not-linear' style={{width: '100%'}} src={proyuan} alt="proyuan" />
                        </div>
                        <div className='wow animate__flipInX' style={style.HPItemH1}>Proactive Strategy</div>
                        <div className='wow animate__flipInX' style={{ ...style.HPItemH1, marginTop: realPx(ratio, 8) }}>and Expertise</div>
                        <div className='wow animate__flipInX' style={style.HPItemH3}>Dig really deep into your existing clients, new brands, industry trends and market data. Opt 360's team of analytic experts and internal research tools will help you deliver unique insights, develop inspiring pitches and deliver world-class campaigns.</div>
                        <div className="animate__animated btn-pulse">
                            <PBtn name='Learn more' icon={homeBtnarow} style={{ marginTop: realPx(ratio, 32) }} />
                        </div>
                    </div>
                    <div style={style.HPItem3} >
                        <img className='wow bounceIn' style={style.pImg2} src={proimg2} alt="icon" />
                        <div className='wow flipInY bg-stuff-pos' style={style.proplante}>
                            <img className='routeAni' style={{width: '100%'}} src={proplante} alt="proplante" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='Home-Opt360-warp'>
                <div className='Home-Opt360' style={style.ho}>
                    <div>
                        <div className='wow animate__flipInX'>
                            <Alert />
                            <div style={style.hoh1}>The Opt360 difference</div>
                            <div style={style.hoh2}>Opt360 Is Your Gateway To Effective Advertising Solutions, Designed To Bring Your Brand Closer To Your Target Audience. Through Our Diverse Range Of Tools And Strategies, Including Personalized Ad Campaigns, Targeted User Acquisition, And Strategic Retargeting Efforts, We Help You Engage With Users While Respecting Their Privacy.</div>
                            <div className='animate__animated btn-pulse'>
                                <PBtn icon={homeBtnarow} style={{ marginTop: realPx(ratio, 32) }} name='Learn more' />
                            </div>
                        </div>
                        <div className='wow animate__flipInY'>
                            <img style={style.hoImg} src={opt_home_diffimg1} alt="icon" />
                        </div>
                    </div>
                    <div style={{ marginTop: realPx(ratio, 80) }}>
                        <img className='animate__animated btn-pulse-star1 proline_stuff' style={style.proline1} src={proline1} alt="icon" />
                        <img className='animate__animated btn-pulse-star2 proline_stuff' style={style.proline2} src={proline2} alt="icon" />
                        {
                            opt360Cards.map((item: any, index: number) => (
                                <div className='wow animate__flipInY' key={index}>
                                    <ColorCard title={item.title} text={item.text} bg={item.bg} icon={item.icon} />
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <div className='Home-growing-warp' style={style.growing}>
                <div className='wow animate__flipInX' style={style.growingh1}>We’re in the business of growing yours</div>
                <div className='wow flipInX-delay' style={style.growingh2}>For more than 15 years, over 140,000 companies have trusted Opt360 to drive performance they can count on.</div>
                <div className='wow animate__flipInY'>
                    <CardSwiper />
                </div>
            </div>
            <div className='Home-touch-warp' style={style.touch}>
                <div className='wow animate__fadeInLeft' style={style.touchh1}>Engage your audiences, drive conversions,</div>
                <div className='wow animate__fadeInLeft' style={style.touchh1}>optimize performance, and repeat.</div>
                <div className='wow animate__fadeInRight' style={style.touchh2}>Learn how Opt360 can help your business do it all.</div>
                <div className="animate__animated btn-pulse">
                    <TBtn name="Get in Touch" style={{ margin: '0 auto', marginTop: realPx(ratio, 40) }} />
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Index;
