import React, { useContext } from 'react';
import './nav.css';
import logo from '../../static/images/opt_home_logo.png';
import Menu from '../menu';
import { RatioContext, realPx } from '../../static/tools/common';

function Index() {
    const ratio = useContext(RatioContext) || 1;
    const style = {
        navHeight: { height: realPx(ratio, 100) },
        logoHeight: {
            marginTop: realPx(ratio, 31), 
            height: realPx(ratio, 38)
        },
    };
    return (
        <div className="Nav-container" style={style.navHeight}>
            <div className="Nav-container-child Nav-container_left">
                <img style={style.logoHeight} src={logo} className="App-logo" alt="logo" />
            </div>
            <div className="Nav-container-child Nav-container_right"><Menu ratio={ratio} realPx={realPx} /></div>
        </div>
    );
}

export default Index;
