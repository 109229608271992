import React, { useContext, useEffect } from 'react';
import './user.css';
import Nav from '../../components/nav';
import Banner from './banner';
import ScrollAd from '../../components/scrollAd';
import { RatioContext, realPx, wowinit } from '../../static/tools/common';
import Footer from '../../components/footer';
import TBtn from '../../components/button/touch';
import useraiw from '../../static/images/opt_useraiw.png';
import useroptimib from '../../static/images/opt_useroptimib.png';
import userimg01 from '../../static/images/opt_userimg01.png';
import proline from '../../static/images/opt_home_proline.png';
import userdyinimg01 from '../../static/images/opt_userdyinimg01.png';
import userdyinimg02 from '../../static/images/opt_userdyinimg02.png';
import userblueline from '../../static/images/opt_userblueline.png';
import userhonline from '../../static/images/opt_userhonline.png';


function Index() {
  const ratio = useContext(RatioContext) || 1;
  const style = {
    home: {
      paddingTop: realPx(ratio, 100)
    },
    touch: {
      paddingTop: realPx(ratio, 87),
      height: realPx(ratio, 400),
    },
    touchh1: {
      fontSize: realPx(ratio, 32),
      lineHeight: realPx(ratio, 38),
      fontWeight: 'bold'
    },
    touchh2: {
      marginTop: realPx(ratio, 24),
      fontSize: realPx(ratio, 16),
      lineHeight: realPx(ratio, 19)
    },
    targeting: {
      height: realPx(ratio, 768),
      paddingLeft: realPx(ratio, 360),
    },
    targetingLeft: {
      marginTop: realPx(ratio, 155),
    },
    targetingLeftH1: {
      fontSize: realPx(ratio, 48),
      lineHeight: realPx(ratio, 56),
      fontWeight: 'bold'
    },
    targetingLeftCardCommon: {
      paddingLeft: realPx(ratio, 28),
      width: realPx(ratio, 610),
      height: realPx(ratio, 180),
      borderRadius: realPx(ratio, 24)
    },
    targetingLeftCard1: {
      marginTop: realPx(ratio, 48),
    },
    targetingLeftCard2: {
      marginTop: realPx(ratio, 24),
      height: realPx(ratio, 180),
    },
    targetingLeftCardImg: {
      width: realPx(ratio, 48),
      marginRight: realPx(ratio, 40),
    },
    targetitargetingLeftCardTitle: {
      fontSize: realPx(ratio, 24),
      lineHeight: realPx(ratio, 28),
      fontWeight: 'bold'
    },
    targetitargetingLeftCardText: {
      marginTop: realPx(ratio, 11),
      width: realPx(ratio, 479),
      fontSize: realPx(ratio, 12),
      lineHeight: realPx(ratio, 18),
    },
    userimg01: {
      position: 'absolute',
      bottom: 0,
      right: realPx(ratio, 391),
      width: realPx(ratio, 502),
    },
    proline: {
      position: 'absolute',
      bottom: realPx(ratio, 703),
      right: realPx(ratio, 827),
      width: realPx(ratio, 114),
    },
    opt360: {
      height: realPx(ratio, 1467),
    },
    userdyinimg01: {
      position: 'absolute',
      width: realPx(ratio, 584),
      top: realPx(ratio, 131),
      left: realPx(ratio, 360),
    },
    userdyinimg02: {
      position: 'absolute',
      width: realPx(ratio, 584),
      bottom: realPx(ratio, 136),
      right: realPx(ratio, 360),
    },
    user_opt360_link: {
      color: '#3182FE',
      fontSize: realPx(ratio, 16),
      lineHeight: realPx(ratio, 19),
    },
    user_opt360_title: {
      marginTop: realPx(ratio, 16),
      fontSize: realPx(ratio, 48),
      lineHeight: realPx(ratio, 56),
      fontWeight: 'bold'
    },
    user_opt360_text: {
      marginTop: realPx(ratio, 16),
      fontSize: realPx(ratio, 16),
      lineHeight: realPx(ratio, 28),
      color: 'rgba(22,27,39,0.5)'
    },
    userdyininfo1: {
      position: 'absolute',
      width: realPx(ratio, 468),
      top: realPx(ratio, 195),
      right: realPx(ratio, 428),
    },
    userdyininfo2: {
      position: 'absolute',
      width: realPx(ratio, 506),
      bottom: realPx(ratio, 257),
      left: realPx(ratio, 390),
    },
    userblueline: {
      position: 'absolute',
      width: realPx(ratio, 81),
      top: realPx(ratio, 128),
      left: realPx(ratio, 537),
    },
    userhonline: {
      position: 'absolute',
      width: realPx(ratio, 67),
      top: realPx(ratio, 589),
      left: realPx(ratio, 687),
    }
  }

  useEffect(() => {
    wowinit();
  }, [])
  return (
    <div className="Home" style={style.home}>
      <div className='Home-nav'>
        <Nav />
      </div>
      <Banner />
      <div className='user-targeting-warp' style={style.targeting}>
        <div style={style.targetingLeft}>
          <div className='wow animate__slideInLeft' style={style.targetingLeftH1}>Audience Smart-</div>
          <div className='wow slideInLeft1' style={style.targetingLeftH1}>Targeting</div>
          <div className='wow slideInLeft2 targetingLeftCard targetingLeftCard1' style={{ ...style.targetingLeftCardCommon, ...style.targetingLeftCard1 }}>
            <img style={style.targetingLeftCardImg} src={useraiw} alt="icon" />
            <div>
              <div style={style.targetitargetingLeftCardTitle}>Algorithm&Data Analysis</div>
              <div style={style.targetitargetingLeftCardText}>Utilizing Sophisticated Algorithms And Data Analytics, Opt360 Accurately Identifies And Engages With The Most Relevant Audience Segments For Advertisers. This Ensures That Ad Campaigns Are Delivered To Users Who Are Highly Likely To Engage With The Content And Take Desired Actions, Such As App Installation Or Making A Purchase. </div>
            </div>
          </div>
          <div className='wow slideInLeft3 targetingLeftCard' style={{ ...style.targetingLeftCardCommon, ...style.targetingLeftCard2 }}>
            <img style={style.targetingLeftCardImg} src={useroptimib} alt="icon" />
            <div>
              <div style={style.targetitargetingLeftCardTitle}>Optimized Targeting</div>
              <div style={style.targetitargetingLeftCardText}>Through Optimized Targeting, Opt360 Enhances Campaign Effectiveness, Driving Higher Conversion Rates And Maximizing Rol For Advertisers.</div>
            </div>
          </div>
        </div>
        <img className='wow animate__slideInRight' style={style.userimg01 as any} src={userimg01} alt="icon" />
        <div className='wow animate__fadeInTopRight' style={style.proline as any}>
          <img style={{ width: '100%' }} src={proline} alt="icon" />
        </div>
      </div>
      <ScrollAd mode="deep" />
      <div className='user-opt360-warp' style={style.opt360}>
        <div className='wow animate__slideInLeft'>
          <img style={style.userdyinimg01 as any} src={userdyinimg01} alt="icon" />
          <img style={style.userblueline as any} src={userblueline} alt="icon" />
          <img style={style.userhonline as any} src={userhonline} alt="icon" />
        </div>
        <div style={style.userdyininfo1 as any}>
          <div className='wow animate__slideInRight' style={style.user_opt360_link}>opt 360</div>
          <div className='wow slideInRight1' style={style.user_opt360_title}>Dynamic Insights</div>
          <div className='wow slideInRight2' style={style.user_opt360_text}>Opt360 Platform Offers Real-Time Data Analysis And Feedback. By Monitoring The Real-Time Performance Of Advertising Campaigns And User Feedback, We Can Adjust Ad Strategies Promptly And Optimize Campaign Effectiveness. This Timely Data Feedback Enables Advertisers To Quickly Assess Ad Performance And Make Real-Time Decisions When Necessary, Thereby Maximizing The Effectiveness And Rol Of Advertising Campaigns.</div>
        </div>
        <div style={style.userdyininfo2 as any}>
          <div className='wow animate__slideInLeft' style={style.user_opt360_link}>opt 360</div>
          <div className='wow slideInLeft1' style={style.user_opt360_title}>Creative Optimization</div>
          <div className='wow slideInLeft2' style={style.user_opt360_text}>Opt360 excels in creative optimization, leveraging cutting-edge technologies to refine and enhance advertising creatives.Drive better ROAS with high-impact creatives. Produce ad assets that resonate with your audience with the best of ad creation tools, at-scale publishing and AI-powered campaign analytics.</div>
        </div>
        <img className='wow animate__slideInRight' style={style.userdyinimg02 as any} src={userdyinimg02} alt="icon" />
      </div>
      <div className='Home-touch-warp' style={style.touch}>
        <div className='wow animate__slideInLeft' style={style.touchh1}>Engage your audiences, drive conversions,</div>
        <div className='wow slideInLeft1' style={style.touchh1}>optimize performance, and repeat.</div>
        <div className='wow slideInLeft2' style={style.touchh2}>Learn how Opt360 can help your business do it all.</div>
        <div className='animate__animated btn-pulse'>
        <TBtn name="Get in Touch" style={{ margin: '0 auto', marginTop: realPx(ratio, 40) }} />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Index;
