import { useEffect } from 'react';
import { wowinit } from './static/tools/common'

function Index() {
    const list = [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
    useEffect(() => {
        
        wowinit();
    }, [])
    return (
        <div>
            {
                list.map((item: any) => <div className={`wow slideInLeft`} key={item} style={{ margin: 10, width: 100, height: 100, background: 'red' }}>{item}</div>)
            }
        </div>
    )
}
export default Index;