import React, { useContext } from 'react';
import './scrollAd.css';
import { RatioContext, realPx } from '../../static/tools/common';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import opt_partner_logo_1 from '../../static/images/opt_partner_logo_1.png';
import opt_partner_logo_2 from '../../static/images/opt_partner_logo_2.png';
import opt_partner_logo_3 from '../../static/images/opt_partner_logo_3.png';
import opt_partner_logo_4 from '../../static/images/opt_partner_logo_4.png';
import opt_partner_logo_5 from '../../static/images/opt_partner_logo_5.png';
import opt_partner_logo_6 from '../../static/images/opt_partner_logo_6.png';
import opt_partner_logo_7 from '../../static/images/opt_partner_logo_7.png';
import opt_partner_logowhite_1 from '../../static/images/opt_partner_logowhite_1.png';
import opt_partner_logowhite_2 from '../../static/images/opt_partner_logowhite_2.png';
import opt_partner_logowhite_3 from '../../static/images/opt_partner_logowhite_3.png';
import opt_partner_logowhite_4 from '../../static/images/opt_partner_logowhite_4.png';
import opt_partner_logowhite_5 from '../../static/images/opt_partner_logowhite_5.png';
import opt_partner_logowhite_6 from '../../static/images/opt_partner_logowhite_6.png';
import opt_partner_logowhite_7 from '../../static/images/opt_partner_logowhite_7.png';

function Index({ mode }: any) {
    const ratio = useContext(RatioContext) || 1;
    const style = {
        sHeight: {
            height: realPx(ratio, 160),
            paddingLeft: realPx(ratio, 360),
            backgroundColor: mode === 'deep' ? 'rgba(49, 117, 254, 1)' : '#DCEFFF'
        },
        sWord: {
            color:  mode === 'deep' ? '#fff' : '#161B27'
        },
        title: {
            fontSize: realPx(ratio, 24),
            lineHeight: realPx(ratio, 32),
            fontWeight: 'bold'
        },
        img: {
            height: realPx(ratio, 57),
        },
        swiper: {
            marginLeft: realPx(ratio, 64),
            width: realPx(ratio, 1066),
        }
    };
    const list = mode === 'deep' ? [
        opt_partner_logowhite_1,
        opt_partner_logowhite_2,
        opt_partner_logowhite_3,
        opt_partner_logowhite_4,
        opt_partner_logowhite_5,
        opt_partner_logowhite_6,
        opt_partner_logowhite_7,
    ] : [
        opt_partner_logo_1,
        opt_partner_logo_2,
        opt_partner_logo_3,
        opt_partner_logo_4,
        opt_partner_logo_5,
        opt_partner_logo_6,
        opt_partner_logo_7,
    ]
    return (
        <div className="ScrollAd-container" style={style.sHeight}>
            <div style={style.sWord}>
                <div style={style.title}>Trusted by</div>
                <div style={style.title}>Top Companies</div>
            </div>
            <div style={style.swiper}>
                <Swiper
                    loop
                    autoplay={{
                        delay: 3000
                    }}
                    spaceBetween={200}
                    slidesPerView={5}
                    modules={[Autoplay]}
                >
                    {
                        list.map((item: any, index: number) => (
                            <SwiperSlide key={index}>
                                <img style={style.img} src={item} alt="com_logo" />
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </div>
        </div>
    );
}

export default Index;
