import React, { useContext } from 'react';
import './banner.css';
import { RatioContext, realPx } from '../../../static/tools/common';
import bannerpeople from '../../../static/images/opt_about_optimg.png';
import homeBtnarow from '../../../static/images/opt_home_btnarow.png';
import 'animate.css';


function Index() {
    const ratio = useContext(RatioContext) || 1;
    const style = {
        bHeight: {
            height: realPx(ratio, 666),
        },
        bannerpeople: {
            position: "absolute",
            top: realPx(ratio, 53),
            left: realPx(ratio, 976),
            width: realPx(ratio, 584)
        },
        info: {
            top: realPx(ratio, 120),
            left: realPx(ratio, 360),
        },
        topBtnIcon: {
            marginRight: realPx(ratio, 10),
            width: realPx(ratio, 22),
        },
        word1: {
            fontSize: realPx(ratio, 56),
            textAlign: 'left',
            fontWeight: 400,
            lineHeight: realPx(ratio, 66), 
        },
        word2: {
            fontSize: realPx(ratio, 56),
            textAlign: 'left',
            fontWeight: 'bold',
            lineHeight: realPx(ratio, 66), 
        },
        word3: {
            fontSize: realPx(ratio, 16),
            lineHeight: realPx(ratio, 28), 
            width: realPx(ratio, 500)
        },
        opt: {
            marginTop: realPx(ratio, 40),
        },
        btn: {
            width: realPx(ratio, 249),
            height: realPx(ratio, 64),
            borderRadius: realPx(ratio, 100),
            fontSize: realPx(ratio, 20),
        },
        btnImgSpan: {
            width: realPx(ratio, 44),
            height: realPx(ratio, 44),
            marginLeft: realPx(ratio, 18),
        },
        btnImg: {
            width: realPx(ratio, 32),
            marginTop: realPx(ratio, 6),
        }
    };
    return (
        <div className="r-Banner-container" style={style.bHeight}>
            <img className='wow animate__fadeInRight bgLight' src={bannerpeople} style={style.bannerpeople as any} alt="bannerpeople" />
            <div className='Banner-info' style={style.info}>
                <div className='Banner-word1 wow animate__fadeInLeft' style={style.word1 as any}>Welcome to</div>
                <div className='Banner-word2  wow animate__fadeInLeft' style={style.word2 as any}>OPT360</div>
                <div className='Banner-word3  wow fadeInLeft1' style={{...style.word3, marginTop: realPx(ratio, 16),}}>Opt360's mission is to empower advertisers to deliver creative</div>
                <div className='Banner-word3  wow fadeInLeft1' style={style.word3}>advertising campaigns that capture consumer attention</div>
                <div className='Banner-word3  wow fadeInLeft1' style={style.word3}>without the use of personal data across current and future</div>
                <div className='Banner-word3  wow fadeInLeft1' style={style.word3}>emerging digital environments at scale.</div>
                <div className='Banner-opt wow Banner-opt-in' style={style.opt}>
                    <div style={{ ...style.btn, marginRight: realPx(ratio, 18) }}>
                        <span>Partner with Us</span>
                        <span className='Banner-opt-icon' style={style.btnImgSpan}>
                            <img src={homeBtnarow} style={style.btnImg} alt="icon" />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;
