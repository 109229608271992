import React, { useContext } from 'react';
import './button.css';
import { RatioContext, realPx } from '../../static/tools/common';

function Index({ name, style }: any) {
    const ratio = useContext(RatioContext) || 1;
    const localStyle = {
        btn: {
            width: realPx(ratio, 203),
            height: realPx(ratio, 64),
            borderRadius: realPx(ratio, 100),
            fontSize: realPx(ratio, 20),
        }
    }
    return (
        <div className='touch-btn' style={{ ...localStyle.btn, ...(style || {}) }}>
            <span>{name || '默认'}</span>
        </div>
    )
}

export default Index;